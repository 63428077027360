/*Module*/
#menu {
  background-color: black;
  padding: 15px;
  transition: all 0.8s;
  background: url(./1.webp) center center;
  background-attachment: fixed;
  background-blend-mode: screen;
  background-size: cover;
}
#menu.navbar-default {
  background-color: black;
  border-color: var(--whiteish);
  box-shadow: 0px 0px 20px black;
}
#menu a.navbar-brand {
  font-family: "lato", sans-serif;
  font-size: 24px;
  font-weight: 100;
  color: var(--whiteish);
  text-transform: uppercase;
}
#menu.navbar-default .navbar-nav > li > a {
  font-family: "Lato", sans-serif;
  text-transform: uppercase;
  color: var(--whiteish);
  font-size: 15px;
  font-weight: 400;
  padding: 8px 2px;
  border-radius: 0;
  margin: 9px 20px 0;
}
#menu.navbar-default .navbar-nav > li > a:after {
  display: block;
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 0;
  height: 2px;
  background: var(--whiteish);
  content: "";
  transition: width 0.2s;
}
#menu.navbar-default .navbar-nav > li > a:hover:after {
  width: 100%;
}
.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
  background-color: transparent;
}
.navbar-default .navbar-nav > .active > a:after,
.navbar-default .navbar-nav > .active > a:hover:after,
.navbar-default .navbar-nav > .active > a:focus:after {
  display: block !important;
  position: absolute !important;
  left: 0 !important;
  bottom: -1px !important;
  width: 100% !important;
  height: 2px !important;
  background: var(--whiteish);
  content: "" !important;
  transition: width 0.2s !important;
}
.navbar-toggle {
  border-radius: 0;
}
.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
  background-color: var(--whiteish);
  border-color: var(--whiteish);
}
.navbar-default .navbar-toggle:hover > .icon-bar {
  background-color: black;
}
.section-title {
  margin-bottom: 70px;
}
.section-title h2 {
  text-shadow: 1px 1px 2px black;
  position: relative;
  margin-top: 10px;
  margin-bottom: 15px;
  padding-bottom: 15px;
  color: var(--whiteish);
}
.section-title h2::after {
  position: absolute;
  content: "";
  background: var(--whiteish);
  height: 4px;
  width: 60px;
  bottom: 0;
  margin-left: -30px;
  left: 50%;
}
.section-title p {
  font-size: 18px;
}
a:focus,
.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
  outline: none;
  outline-offset: none;
}
.authorLogo {
  max-height: 60px;
}
nav a:hover {
  color: unset;
}
nav h1 {
  font-family: "Lato", sans-serif;
  text-transform: uppercase;
}
