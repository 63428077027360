/*Module*/

.linkyPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 0;
  padding-top: 20vh;
  padding-bottom: 10vh;
  height: 100vh;
  background: url(./2.webp) center center;
  background-attachment: fixed;
  background-blend-mode: overlay;
  background-size: cover;
  box-shadow: 0px 0px 15px inset;
}
.linkyPage h1 {
  font-weight: 400;
  font-size: 28px;
}
.linkyPage p {
  margin-bottom: 50px !important;
  text-align: justify;
}
.linkyPage .fa-arrow-left {
  visibility: hidden;
}

.linkTreeLink {
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-size: 20px;
  text-transform: uppercase;
  /*margin: auto !important;*/
  padding: 10px !important;
  color: var(--whiteish);
  width: 70% !important;
  margin: 25px auto;
  transform: scale(1);
  pointer: cursor;
  transition: 0.4s;
  padding: 3px;
  border-radius: 5px;
  display: flex;
  justify-content: space-around;
  background-color: black;
  transition: all 0.8s;
  background: url(./1.webp) center center;
  background-attachment: fixed;
  background-blend-mode: screen;
  background-size: cover;
}

.linkTreeLink:hover,
.linkTreeLink:focus,
.linkTreeLink:active,
.linkTreeLink:visited {
  transform: scale(1.05);
  color: inherit;
}


/*add space in I, check a font */
