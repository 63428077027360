#a-propos {
  padding-top: 100px;
  padding-bottom: 100px;
  box-shadow: 0px 0px 15px inset;
  background: url(./2.webp) center center;
  background-size: cover;
  color: black;
  background-attachment: fixed;
}
#a-propos h3 {
  color: black;
  font-size: 22px;
  margin: 0 0 20px;
}
#a-propos h2 {
  color: black;
  position: relative;
  margin-bottom: 15px;
  padding-bottom: 15px;
}
#a-propos h2::after {
  position: absolute;
  content: "";
  background: black;
  height: 4px;
  width: 60px;
  bottom: 0;
  left: 0;
}
#a-propos .about-text li {
  margin-bottom: 6px;
  margin-left: 6px;
  list-style: none;
  padding: 0;
}
#a-propos p {
  line-height: 24px;
  margin: 30px 0;
  text-align: justify;
  text-align: justify;
  font-size: 17px;
}
.photoAuteurWrapper {
  position: relative;
  max-height: 300px;
}
.photoAuteur {
  max-height: 250px;
  border-radius: 50%;
  margin: auto auto 50px auto;
  box-shadow: 5px 5px 10px black;
}
.photoAuteur2 {
  margin-top: 0.2px;
  position: absolute;
  top: 0.2px;
  right: 0;
  left: 0;
  bottom: 0;
  object-fit: contain;
  opacity: 0;
  transition: opacity 1s;
}
.photoAuteur2:hover {
  opacity: 1;
}
