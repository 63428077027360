/***ANIMATIONS***/
@keyframes swing-in-bottom-fwd {
  0% {
    -webkit-transform: rotateX(100deg);
    transform: rotateX(100deg);
    -webkit-transform-origin: bottom;
    transform-origin: bottom;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
    -webkit-transform-origin: bottom;
    transform-origin: bottom;
    opacity: 1;
  }
}
@keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes swing-in-right-fwd {
  0% {
    -webkit-transform: rotateY(-100deg);
    transform: rotateY(-100deg);
    -webkit-transform-origin: right;
    transform-origin: right;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
    -webkit-transform-origin: right;
    transform-origin: right;
    opacity: 1;
  }
}
@keyframes anipers {
  0% {
    transform: translateX(0px);
    transform-origin: center;
    transform: perspective(1000px) rotateY(25deg);
  }
  25% {
    transform: translateX(-10);
  }
  50% {
    transform: translateX(10px);
    transform-origin: center;
    transform: perspective(1000px) rotateY(-25deg);
  }
  100% {
    transform: translateX(0px);
    transform-origin: center;
    transform: perspective(1000px) rotateY(25deg);
  }
}
@keyframes blink {
  78% {
    color: #c8edf5;
    text-shadow: inherit;
  }
  79% {
    color: #c8edf5;
  }
  80% {
    text-shadow: none;
  }
  81% {
    color: #c8edf5;
    text-shadow: inherit;
  }
  82% {
    color: #c8edf5;
    text-shadow: none;
  }
  83% {
    color: #c8edf5;
    text-shadow: inherit;
  }
  92% {
    color: #c8edf5;
    text-shadow: none;
  }
  92.5% {
    color: #c8edf5;
    text-shadow: inherit;
  }
}
@keyframes flicker-in-1 {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
  }
  10.1% {
    opacity: 1;
  }
  10.2% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  20.1% {
    opacity: 1;
  }
  20.6% {
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  30.1% {
    opacity: 1;
  }
  30.5% {
    opacity: 1;
  }
  30.6% {
    opacity: 0;
  }
  45% {
    opacity: 0;
  }
  45.1% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  55% {
    opacity: 1;
  }
  55.1% {
    opacity: 0;
  }
  57% {
    opacity: 0;
  }
  57.1% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  60.1% {
    opacity: 0;
  }
  65% {
    opacity: 0;
  }
  65.1% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  75.1% {
    opacity: 0;
  }
  77% {
    opacity: 0;
  }
  77.1% {
    opacity: 1;
  }
  85% {
    opacity: 1;
  }
  85.1% {
    opacity: 0;
  }
  86% {
    opacity: 0;
  }
  86.1% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@keyframes blink404 {
  78% {
    color: var(--blackish);

    text-shadow: inherit;
  }
  79% {
    /*color: #ffef83;*/
  }
  80% {
    text-shadow: none;
  }
  81% {
    color: var(--blackish);

    text-shadow: inherit;
  }
  82% {
    color: var(--blackish);

    text-shadow: none;
  }
  83% {
    color: var(--blackish);

    text-shadow: inherit;
  }
  92% {
    color: var(--blackish);

    text-shadow: none;
  }
  92.5% {
    color: var(--blackish);
    text-shadow: inherit;
  }
}
